import * as React from "react"

import Navigation from "./navigation"
import { HeroPicker } from "./hero/heroPicker"
import Footer from "./Footer/Footer"
import Content from "./Content/Content"
import SidebarForm from "./Form/SidebarForm"
import CTABlock from "./CTABlock/CTABlock"
import CookiesBanner from "./CookiesBanner"
import { PageContext } from "../context/pageProvider"

const StandardPage = () => {
  const pageContext = React.useContext(PageContext)
  const { hero, cta, content, contentData, additionalContents, _type } =
    pageContext.page

  return (
    <>
      <CookiesBanner />
      <Navigation />
      <div
        className={`${
          pageContext.meta.settings?.callInHeader ? "pt-28" : "pt-16"
        } sm:pt-10 lg:pt-8 xl:pt-4`}
      >
        <HeroPicker hero={hero} cta={cta} />
      </div>
      <div className="content_sidebar elva flex justify-between lg:px-4">
        <Content
          typePage={_type}
          content={content}
          contentData={contentData}
          additionalContents={additionalContents}
        />
        <div className={_type === "startpage" ? "hiddenMobile" : ""}>
          <SidebarForm />
        </div>
      </div>
      <CTABlock />
      <Footer />
    </>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default StandardPage
