import Slant from "./slant"
import Card from "./card"
import * as React from "react"
import FullScreen from "./fullScreen"
import Splitted from "./splitted"

export function HeroPicker({ hero, cta }) {
  const pickHero = () => {
    const combinedProps = { hero, cta }

    const herotype = hero?.herotype

    switch (herotype) {
      case "slant":
        return <Slant {...combinedProps} />
      case "card":
        return <Card {...combinedProps} />
      case "fullScreen":
        return <FullScreen {...combinedProps} />
      case "splitted":
        return <Splitted {...combinedProps} />
      default:
        return <Slant {...combinedProps} />
    }
  }

  return pickHero()
}

export default HeroPicker
